<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
          alt=""
        />
        <div
          class="absolute inset-0 bg-gray-700"
          style="mix-blend-mode: multiply"
          aria-hidden="true"
        />
      </div>
      <div
        class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8"
      >
        <h1
          class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
          data-aos="flip-down"
          data-aos-easing="linear"
        >
          Our Portfolio.
        </h1>
        <p
          class="mt-6 text-xl text-indigo-100 max-w-3xl"
          data-aos="fade-in"
          data-aos-easing="linear"
        >
          Trusted by companys across Ireland.
        </p>
      </div>
    </div>
    <LogoCloud />
    <PortfolioBody />
    <BrandCloud />
    <ContactBar />
  </div>
</template>

<script>
import PortfolioBody from "../components/pagebody/PortfolioBody.vue";
import ContactBar from "../components/other/ContactBar.vue";
import LogoCloud from "../components/base/LogoCloud.vue";
import BrandCloud from "../components/base/BrandCloud.vue";

export default {
  name: "Portfolio",
  components: {
    ContactBar,
    PortfolioBody,
    LogoCloud,
    BrandCloud,
  },
};
</script>
