<template>
  <div>
    <div class="bg-gray-50">
      <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12">
          <div
            class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
          >
            <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Portfolio.
            </h2>
            <p class="text-xl text-gray-700">
              Amongst our portfolio we have completed projects for
              pharmaceutical giant BD(Becton, Dickinson and Company) , The
              world's largest real estate company CBRE, as well as top tier
              irish companies such as Flynn & Pelco and Organizations/Government
              Bodies such as The National Lottery, The Department of Foreign
              Affairs & Dublin City Council to name a few.
            </p>
          </div>
          <ul
            class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            <li v-for="person in people" :key="person.name" data-aos="fade-up">
              <div class="space-y-4">
                <div class="aspect-w-3 aspect-h-2">
                  <img
                    class="object-cover shadow-lg rounded-lg bh"
                    :src="person.imageUrl"
                    alt=""
                  />
                </div>

                <div class="space-y-2">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3>{{ person.name }}</h3>
                    <p class="text-indigo-600">{{ person.role }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="max-w-md mx-auto">
            <router-link to="/services" class="btg bh">
              View Services
            </router-link>
            <div class="h-5"></div>
            <router-link to="/sectors" class="btg bh">
              View Sectors
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const people = [
  {
    imageUrl:
      "https://cdn.pixabay.com/photo/2015/11/15/20/49/modern-office-1044807_960_720.jpg",
  },
  {
    imageUrl:
      "https://cdn.pixabay.com/photo/2018/08/06/19/49/design-3588214_960_720.jpg",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
  },
  {
    imageUrl:
      "https://cdn.pixabay.com/photo/2017/03/28/12/11/chairs-2181960_960_720.jpg",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1557064262-6acc904bffbd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1583564345817-9735ebbc0569?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1873&q=80",
  },
  {
    imageUrl:
      "https://images.pexels.com/photos/1457844/pexels-photo-1457844.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1539639885136-56332d18071d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
  },
  {
    imageUrl:
      "https://images.unsplash.com/photo-1577002815022-8db2cf795db6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80",
  },
  // More people...
];

export default {
  name: "PortfolioBody",
  setup() {
    return {
      people,
    };
  },
};
</script>
